import { ApolloClient, InMemoryCache } from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

export const apolloClient = new ApolloClient({
  uri: process.env.BACKEND_URL,
  cache: new InMemoryCache(),
  credentials: 'include',
  link: createUploadLink({
    uri: process.env.BACKEND_URL,
    credentials: 'include',
    headers: {
      'apollo-require-preflight': 'true',
    },
  }),
});
