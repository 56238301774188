'use client';

import { apolloClient } from '@/apollo-client';
import { ApolloProvider } from '@apollo/client';

type Props = {
  children: React.ReactNode;
  params: {
    locale: string;
  };
};

export default function RootLayout({ children }: Props) {
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
